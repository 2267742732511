import React, { useEffect, useState, useMemo } from "react";
import { useAuth } from "../services/auth";
import axios from "axios";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Button,
	IconButton,
	Menu,
	MenuItem,
	Alert,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	FormControl,
	InputLabel,
	Select,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const TenantServicesTab = () => {
	const { user } = useAuth();
	const [tenantServices, setTenantServices] = useState([]);
	const [tenants, setTenants] = useState([]);
	const [services, setServices] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [currentTenantService, setCurrentTenantService] = useState({
		tenant_id: "",
		service_id: "",
		url: "",
	});
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedTenantService, setSelectedTenantService] = useState(null);
	const API_URL =
		window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;

	useEffect(() => {
		fetchTenantServices();
		fetchTenants();
		fetchServices();
	}, [user, API_URL]);

	const fetchTenantServices = async () => {
		setLoading(true);
		try {
			const response = await axios.get(`${API_URL}/api/tenant-services`, {
				headers: { Authorization: `Bearer ${user.access_token}` },
			});
			setTenantServices(response.data);
		} catch (error) {
			console.error("Error fetching tenant services:", error);
			setError("Failed to fetch tenant services.");
		} finally {
			setLoading(false);
		}
	};

	const fetchTenants = async () => {
		try {
			const response = await axios.get(`${API_URL}/api/tenants`, {
				headers: { Authorization: `Bearer ${user.access_token}` },
			});
			setTenants(response.data);
		} catch (error) {
			console.error("Error fetching tenants:", error);
		}
	};

	const fetchServices = async () => {
		try {
			const response = await axios.get(`${API_URL}/api/services`, {
				headers: { Authorization: `Bearer ${user.access_token}` },
			});
			setServices(response.data);
		} catch (error) {
			console.error("Error fetching services:", error);
		}
	};

	const handleAdd = () => {
		setCurrentTenantService({ tenant_id: "", service_id: "", url: "" });
		setDialogOpen(true);
	};

	const handleEdit = (tenantService) => {
		setCurrentTenantService(tenantService);
		setDialogOpen(true);
	};

	const handleDelete = async (tenant_id, service_id) => {
		try {
			await axios.delete(`${API_URL}/api/tenant-services`, {
				headers: { Authorization: `Bearer ${user.access_token}` },
				data: { tenant_id, service_id }, // Send composite keys in the request body
			});
			setTenantServices(
				tenantServices.filter(
					(ts) => ts.tenant_id !== tenant_id || ts.service_id !== service_id,
				),
			);
		} catch (error) {
			console.error("Error deleting tenant service:", error);
			setError("Failed to delete tenant service.");
		}
	};

	const handleDialogClose = () => {
		setDialogOpen(false);
	};

	const handleDialogSave = async () => {
		try {
			if (currentTenantService.tenant_id && currentTenantService.service_id) {
				await axios.put(
					`${API_URL}/api/tenant-services`,
					currentTenantService,
					{
						headers: { Authorization: `Bearer ${user.access_token}` },
					},
				);
				setTenantServices(
					tenantServices.map((ts) =>
						ts.tenant_id === currentTenantService.tenant_id &&
						ts.service_id === currentTenantService.service_id
							? currentTenantService
							: ts,
					),
				);
			} else {
				const response = await axios.post(
					`${API_URL}/api/tenant-services`,
					currentTenantService,
					{
						headers: { Authorization: `Bearer ${user.access_token}` },
					},
				);
				setTenantServices([...tenantServices, response.data]);
			}
			setDialogOpen(false);
		} catch (error) {
			console.error("Error saving tenant service:", error);
			setError("Failed to save tenant service.");
		}
	};

	const handleMenuOpen = (event, tenantService) => {
		setAnchorEl(event.currentTarget);
		setSelectedTenantService(tenantService);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		setSelectedTenantService(null);
	};

	const tableRows = useMemo(() => {
		return tenantServices.map((ts) => (
			<TableRow
				style={{ color: "white" }}
				key={`${ts.tenant_id}-${ts.service_id}`}
			>
				<TableCell style={{ color: "white" }}>
					{" "}
					{tenants.find((t) => t.id === ts.tenant_id)?.name || "N/A"}
				</TableCell>
				<TableCell style={{ color: "white" }}>
					{services.find((s) => s.id === ts.service_id)?.name || "N/A"}
				</TableCell>
				<TableCell style={{ color: "white" }}>{ts.url || "N/A"}</TableCell>
				<TableCell style={{ color: "white" }}>
					<IconButton
						style={{ color: "white" }}
						onClick={(event) => handleMenuOpen(event, ts)}
					>
						<MoreVertIcon />
					</IconButton>
				</TableCell>
			</TableRow>
		));
	}, [tenantServices, tenants, services]);

	if (loading) return <CircularProgress />;
	if (error) return <Alert severity="error">{error}</Alert>;

	return (
		<div>
			<h2>Tenant Services</h2>
			<Button
				style={{ color: "white" }}
				variant="contained"
				onClick={handleAdd}
			>
				Add Tenant Service
			</Button>
			<TableContainer style={{ color: "white" }}>
				<Table style={{ color: "white" }}>
					<TableHead style={{ color: "white" }}>
						<TableRow style={{ color: "white" }}>
							<TableCell style={{ color: "white" }}>Tenant</TableCell>
							<TableCell style={{ color: "white" }}>Service</TableCell>
							<TableCell style={{ color: "white" }}>URL</TableCell>
							<TableCell style={{ color: "white" }}>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{tenantServices.length > 0 ? (
							tableRows
						) : (
							<TableRow style={{ color: "white" }}>
								<TableCell colSpan={4}>No tenant services found</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>

			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleMenuClose}
				style={{ color: "white" }}
			>
				<MenuItem
					onClick={() => {
						handleEdit(selectedTenantService);
						handleMenuClose();
					}}
				>
					Edit
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleDelete(
							selectedTenantService.tenant_id,
							selectedTenantService.service_id,
						);
						handleMenuClose();
					}}
				>
					Delete
				</MenuItem>
			</Menu>

			<Dialog
				style={{ color: "white" }}
				open={dialogOpen}
				onClose={handleDialogClose}
			>
				<DialogTitle>
					{currentTenantService.tenant_id && currentTenantService.service_id
						? "Edit Tenant Service"
						: "Add Tenant Service"}
				</DialogTitle>
				<DialogContent style={{ color: "white" }}>
					<FormControl style={{ color: "white" }} fullWidth margin="dense">
						<InputLabel>Tenant</InputLabel>
						<Select
							value={currentTenantService.tenant_id}
							onChange={(e) =>
								setCurrentTenantService({
									...currentTenantService,
									tenant_id: e.target.value,
								})
							}
						>
							{tenants.map((tenant) => (
								<MenuItem key={tenant.id} value={tenant.id}>
									{tenant.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl fullWidth margin="dense">
						<InputLabel>Service</InputLabel>
						<Select
							value={currentTenantService.service_id}
							onChange={(e) =>
								setCurrentTenantService({
									...currentTenantService,
									service_id: e.target.value,
								})
							}
						>
							{services.map((service) => (
								<MenuItem key={service.id} value={service.id}>
									{service.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<TextField
						margin="dense"
						label="URL"
						type="text"
						fullWidth
						value={currentTenantService.url}
						onChange={(e) =>
							setCurrentTenantService({
								...currentTenantService,
								url: e.target.value,
							})
						}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDialogClose}>Cancel</Button>
					<Button onClick={handleDialogSave}>Save</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default TenantServicesTab;
