import { Link } from "react-router-dom";
import styled from "styled-components";

export const FooterBar = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 10% 0 10%;
    background-color: #030D1A;
	color: #ffffff80;
    margin-bottom: 5%;
    margin-top: 20%;
`;

export const LinkWrapper = styled.div`
    height: fit-content;
`;

export const Rights = styled.p`
    text-decoration: none;
	font-family: var(--light-font);
`;

export const LastLine = styled.div`
    display: flex;
    justify-content: space-between  ;
    background-color: #030D1A;
    align-items: center;
    width: 100%;
    margin-bottom: 5%;  

    @media only screen and (max-width: 730px) {
        font-size: 0.5rem;
    }
`;

export const Credentials = styled.a`
	text-decoration: none;
	color: #ffffff80;
	font-family: var(--light-font);

    &:hover {
        color: #4deef2;
    }

    @media only screen and (max-width: 730px) {
        font-size: 0.5rem;
    }
`;

export const LicenseLink = styled(Link)`
    text-decoration: none;
	color: #ffffff80;
	font-family: var(--light-font);

    &:hover {
        color: #4deef2;
    }

    @media only screen and (max-width: 730px) {
        font-size: 0.5rem;
    }
`;
