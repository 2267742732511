import { lazy } from "react";
import {
	ServiceContainer,
	ServiceItem,
	ServiceTitle,
	Subheader,
	TheBox,
	IconAndNameWrapper,
	InformationWrapper,
	Description,
	Bouncer,
	LoadingWrapper,
	TrademartStatement,
} from "./styledComponents/ServiceBox.styled";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../services/auth";
import axios from "axios";
import { Alert } from "@mui/material";
import img from "../assets/buildlinx-tab-logo.png";

const InformationIcon = lazy(
	() => import("../assets/noun-info-5511776-FFFFFF"),
);

const InteractionArrow = lazy(() => import("../assets/interaction-arrow"));

export default function ServiceBox() {
	const { user } = useAuth();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const API_URL =
		window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;
	const [informationActive, setInformationActive] = useState(false);
	const [allServices, setAllServices] = useState([]);

	// only if we want to throw the trademark declaration into the footer
	// const [showTrademark, setShowTrademark] = useState(false);
	// useMemo(() => {
	// 	if (allServices.some((service) => service.groups[0] === "Development")) {
	// 		setShowTrademark(true);
	// 	}
	// }, [allServices]);

	useEffect(() => {
		const fetchServices = async () => {
			if (!user) return;

			setLoading(true);
			try {
				const response = await axios.get(`${API_URL}/api/services`, {
					headers: { Authorization: `Bearer ${user.access_token}` },
				});
				const allServices = response.data;

				// Extract user's tenant ID
				//THIS DOESNT REFLECT THE CURRENT DATA STRUCTURE?!
				const userTenantId =
					user.profile["urn:zitadel:iam:user:resourceowner:id"];

				// Map through services to determine the correct URL
				const servicesWithUrls = allServices.map((service) => {
					const tenantService = service.Tenants.find(
						(tenant) => tenant.organization_id === userTenantId,
					)?.TenantService;
					return {
						...service,
						url: tenantService?.url || service.default_url, // Use tenant-specific URL if available
					};
				});

				setAllServices(servicesWithUrls);
			} catch (error) {
				console.error("Error fetching services:", error);
				setError("Failed to fetch services.");
			} finally {
				setLoading(false);
			}
		};

		fetchServices();
	}, [user, API_URL]);

	const customerApps = allServices
		.filter((dev) => {
			return !dev.groups[0];
		})
		.sort((a, b) => {
			if (a.id === 20) return -1;
			if (b.id === 20) return 1;

			if (a.id === 21) return -1;
			if (b.id === 21) return 1;

			return 0;
		});

	function lookForTools() {
		return allServices.some((svc) => {
			return svc.groups[0] === "Development";
		});
	}

	function lookForExtras() {
		return allServices.some((svc) => {
			return svc.groups[0] === "Dies und Das";
		});
	}

	const displayIcon = (iconData) => {
		if (!iconData) return null;
		const blob = new Blob([new Uint8Array(iconData.data)], {
			type: "text/img",
		});
		const url = URL.createObjectURL(blob);
		return (
			<img
				src={url}
				alt="Service Icon"
				style={{ width: "49.25px", height: "49.25px" }}
			/>
		);
	};

	const timerID = useRef(null);

	if (loading)
		return (
			<LoadingWrapper>
				<Bouncer height="60" width={"60"} src={img} />
			</LoadingWrapper>
		);

	if (error) return <Alert severity="error">{error}</Alert>;

	return (
		<TheBox>
			<ServiceContainer>
				{allServices.length > 0 ? (
					customerApps.map((service) => (
						<div key={service.id}>
							<ServiceItem href={`${service.url}`} target="_blank">
								<InteractionArrow />
								<IconAndNameWrapper $hover={informationActive}>
									{displayIcon(service.iconData)}
									<ServiceTitle>{service.displayName}</ServiceTitle>
								</IconAndNameWrapper>

								<Description
									lang="de"
									$hover={informationActive === service.id}
								>
									{service.description}
								</Description>
							</ServiceItem>
							<InformationWrapper
								onClick={() => {
									clearTimeout(timerID.current);
									informationActive === service.id
										? setInformationActive(false)
										: setInformationActive(service.id);
									timerID.current = setTimeout(
										() => setInformationActive(false),
										3700,
									);
								}}
							>
								<InformationIcon />
							</InformationWrapper>
						</div>
					))
				) : (
					<p>Leider keine Services gefunden</p>
				)}
			</ServiceContainer>
			{allServices.length > 0 && lookForTools() > 0 ? (
				<>
					<Subheader>Tools für Developer &hearts;</Subheader>
					<ServiceContainer>
						{allServices
							.filter((service) => {
								return (
									service?.groups[0] === "Development" ||
									service?.groups[0] === "Tools"
								);
							})
							.map((service) => (
								<div key={service.id}>
									<ServiceItem
										key={service.id}
										href={`${service.url}`}
										target="_blank"
									>
										<InteractionArrow className="arrow" />
										<IconAndNameWrapper>
											{displayIcon(service.iconData)}
											<ServiceTitle>{service.displayName}</ServiceTitle>
										</IconAndNameWrapper>
										<Description
											lang="de"
											$hover={informationActive === service.id}
										>
											{service.description}
										</Description>
									</ServiceItem>
									<InformationWrapper
										onClick={() => {
											clearTimeout(timerID.current);
											informationActive === service.id
												? setInformationActive(false)
												: setInformationActive(service.id);
											timerID.current = setTimeout(
												() => setInformationActive(false),
												3700,
											);
										}}
									>
										<InformationIcon />
									</InformationWrapper>
									{service.id === 4 ? (
										<TrademartStatement>
											GITLAB is a trademark of GitLab Inc. in the United States
											and other countries and regions
										</TrademartStatement>
									) : null}
									{service.id === 18 ? (
										<TrademartStatement>
											Slack is a registered trademark and service mark of Slack
											Technologies, Inc.
										</TrademartStatement>
									) : null}
									{service.id === 7 ? (
										<TrademartStatement>
											InfluxDB™ is a trademark owned by InfluxData, which is not
											affiliated with, and does not endorse, the Buildlinx GmbH.
										</TrademartStatement>
									) : null}
								</div>
							))}
					</ServiceContainer>
				</>
			) : null}
			{allServices.length > 0 && lookForExtras() ? (
				<>
					<Subheader>🍕</Subheader>
					<ServiceContainer>
						{allServices
							.filter((dev) => {
								return dev.groups[0] === "Dies und Das";
							})
							.map((service) => (
								<div key={service.id}>
									<ServiceItem
										key={service.id}
										href={`${service.url}`}
										target="_blank"
									>
										<InteractionArrow className="arrow" />
										<IconAndNameWrapper>
											{displayIcon(service.iconData)}
											<ServiceTitle>{service.displayName}</ServiceTitle>
										</IconAndNameWrapper>
										<Description
											lang="de"
											$hover={informationActive === service.id}
										>
											{service.description}
										</Description>
									</ServiceItem>
									<InformationWrapper
										onClick={() => {
											clearTimeout(timerID.current);
											informationActive === service.id
												? setInformationActive(false)
												: setInformationActive(service.id);
											timerID.current = setTimeout(
												() => setInformationActive(false),
												3700,
											);
										}}
									>
										<InformationIcon />
									</InformationWrapper>
								</div>
							))}
					</ServiceContainer>
				</>
			) : null}
		</TheBox>
	);
}
