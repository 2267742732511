import styled from "styled-components";

const IconWrapper = styled.div`
	align-self: flex-end;
	cursor: pointer;
	z-index: 1005;

	@media only screen and (max-width: 730px) {
		width:40px;
		height: 2px;
}

`;

export default function CrossIcon({ onIconClick }) {
	return (
		<IconWrapper onClick={onIconClick}>
			<svg
				width="40px"
				height="40px"
				version="1.1"
				viewBox="15 23 115 120"
				xmlns="http://www.w3.org/2000/svg"
			>
				<title>Closing Icon</title>
				<path
					d="m38.422 38.422c0.73828-0.73828 1.9336-0.73828 2.6719 0l8.9062 8.9062 8.9062-8.9062c0.73828-0.73828 1.9336-0.73828 2.6719 0s0.73828 1.9336 0 2.6719l-8.9062 8.9062 8.9062 8.9062c0.73828 0.73828 0.73828 1.9336 0 2.6719s-1.9336 0.73828-2.6719 0l-8.9062-8.9062-8.9062 8.9062c-0.73828 0.73828-1.9336 0.73828-2.6719 0s-0.73828-1.9336 0-2.6719l8.9062-8.9062-8.9062-8.9062c-0.73828-0.73828-0.73828-1.9336 0-2.6719z"
					fill="#fff"
				/>
			</svg>
		</IconWrapper>
	);
}
