import styled from "styled-components";

export const BlurrBackdrop = styled.div`
    position: absolute;
    top: 0%;
    height: 100%;
    width: 100%;
    background-color: rgb(0, 0, 0, .6);
    transition: background-color 500ms ease-in;
    z-index: 1000;
`;

export const ProfileWrapper = styled.div`
    position : absolute;
    display: flex;
    left: 33%;
    top: 26%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 350px;
    width: 600px;
    color: white;
    background-color: #0E1F31;
    background-image: radial-gradient(49.85% 49.85% at 50.06% 0%, rgba(106, 198, 198, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
    border-radius: 15px;
    border: 1.03px solid #373B3C;
    /* border-color: rgba(77, 237, 242, 0.6); */
    z-index: 1001;


    @media only screen and (max-width: 730px) {
        height: 190px;
        width: 300px;
        left: 10%;
        gap:10px;   
    }
`;

export const Headder = styled.h2`
    font-family: var(--bold-font);
    text-align: center;
    margin: 0 0 40px 0;
    padding: 0;

    @media only screen and (max-width: 730px) {
    
    font-size: 1rem;
        margin: 0   ;
}
`;

export const Form = styled.form`
    display: flex;
    width: 450px;
    justify-content: center;
    align-items: center;
    z-index: 10002;

    @media only screen and (max-width: 730px) {
        width: 200px;
        gap: 5px;   
    }
`;

export const Label = styled.label`
    font-family: var(--light-font);
    align-self: flex-start;
    margin-left: -8px;

    @media only screen and (max-width: 730px) {
        width: 200px;
        font-size: 0.6rem;
    }
    `;

export const TextField = styled.input`
    width: 450px;
    height: 28px;
    padding: 7px 0 7px 15px;
    margin: 6px 0;
    border: none;
    border-radius: 9px;
    font-family: var(--light-font);

    &:focus-visible{
        outline: none;
    }

    @media only screen and (max-width: 730px) {
    height: auto;
    width: 200px;
    padding: 5px 0 5px 10px;
}
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    @media only screen and (max-width: 730px) {
    height: auto;   
    margin: 0 ;
}
`;

export const Button = styled.button`
    font-family: var(--basic-font);
    width: 110px;
    margin: 30px 0 0 0;
    padding: 10px;
    color: black;
    text-align: center;
    background-color: #4dedf2;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    @media only screen and (max-width: 730px) { 
    width: 170px;
    margin: 0;
    width: 90px;
    font-size: 0.7rem;
    padding: 6px;
}
`;
