import styled from "styled-components";
import img from "./../../assets/Buildlinx-Banner-higherRes.webp";
import { Link } from "react-router-dom";
import { Avatar, Menu, MenuItem, Divider, IconButton } from "@mui/material";

// MENUE BAR HERE
export const ShellBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    width: 100%;
    padding: 4px 0 4px 0;
    background-color: #1B1B1B;
    color: white;

    @media only screen and (max-width: 1150px) {
      height: 40px;
     }

   @media only screen and (max-width: 730px) {
      height: 35px;
   }
`;

export const BlxLogo = styled.img`
    max-height: 32px;

    @media only screen and (max-width: 1150px) {
      max-height: 29px;
     }

   @media only screen and (max-width: 730px) {
      max-height: 17px;
   }
`;

export const ShellLink = styled.a`
    text-decoration: none;
    color: white;
    margin-left: 3%;
    
    @media only screen and (max-width: 1150px) {
      max-height: 29px;
     }

   @media only screen and (max-width: 730px) {
      max-height: 17px;
   }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    margin-right: 3%;
`;

export const AdminLink = styled(Link)`
    color: white;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    text-decoration: none;
    margin-right: 5px;
    padding: 6px 11px 6px 11px;

    &:hover{
        border: 1px solid #373B3C;
        border-radius: 6px;
        background-color: rgb(255, 255, 255, 0.04);
    }

    @media only screen and (max-width: 1150px) {
      font-size: 11px;
     }

   @media only screen and (max-width: 730px) {
      font-size: 9px;
   }
`;

// Material UI Items
export const HeadUser = styled.h3`
  font-family: var(--light-font);
  font-size: 19px;
  font-weight: 400;
  margin-right: 20px;

  @media only screen and (max-width: 1150px) {
      font-size: 13px;
     }

   @media only screen and (max-width: 730px) {
      font-size: 11px;
      margin-right: 15px;
   }
`;

export const UserMenu = styled(Menu)`
  & .MuiPaper-root {
    background: rgba(27, 27, 27, 1);
    color: white;
    min-width: 220px;  
    width: auto;
    max-width: 300px;
    border-radius: 4px 4px 15px 15px;
    overflow: hidden;
  }
`;

export const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 16px !important;
  gap: 5px;  
`;

//Avatar Circle and Initials
export const UserButton = styled(IconButton)`
  height: 40px !important;
  width: 40px !important;
  z-index: 2000;

    @media only screen and (max-width: 1150px) {
      height: 37px !important;
      width: 37px !important;
     }

    @media only screen and (max-width: 730px) {
      height: 30px !important;
      width: 30px !important;
   }
`;

export const UserInitials = styled(Avatar)`
  font-family: var(--light-font);
  font-weight: 400;
  font-size: 15px !important;
  color: white;
  background-image: linear-gradient(
    180deg,
    hsl(247deg 100% 92%) 0%,
    hsl(252deg 98% 90%) 10%,
    hsl(257deg 96% 88%) 25%,
    hsl(262deg 95% 86%) 62%,
    hsl(267deg 94% 84%) 83%,
    hsl(272deg 93% 82%) 91%,
    hsl(277deg 93% 81%) 95%,
    hsl(281deg 92% 79%) 97%,
    hsl(286deg 92% 77%) 99%,
    hsl(291deg 91% 75%) 99%,
    hsl(296deg 91% 73%) 100%
  );
  padding: 0px;
  margin-right: 10px;

  @media only screen and (max-width: 1150px) {
      font-size: 14px !important;
      height: 37px !important;
      width: 37px !important;
     }

   @media only screen and (max-width: 730px) {
      font-size: 12px !important;
      height: 33px !important;
      width: 33px !important;
   }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserName = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
`;

export const UserEmail = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: #797979;
  font-weight: 500; 
`;

export const StyledDivider = styled(Divider)`
  background-color: #444;
  /* margin: 8px 16px; */
  margin: 0px !important;
  width: 100%;
`;

export const MenuEntry = styled(MenuItem)`

  padding: 13px 16px !important;

  font-family: 'Inter', sans-serif;
  font-size: 14px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }

  svg {
    margin: 0 24px 0 7px;
    color: #797979;
  }
`;

// BANNER HERE
export const Banner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 10px 0;
    height: 235px;
    width: 100%;
    background-image: url(${img});
    background-color: #030D1A;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

       
    @media only screen and (max-width: 1150px) {
      height: 190px;
     }

   @media only screen and (max-width: 730px) {
      height: 140px;
   }

`;

export const IconAndHeader = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(322px * 3 + 32px * 2 + 11.5px * 2);

    @media only screen and (max-width: 1150px) {
      width: calc(322px* 2 + 32px* 1 + 11.5px* 2);
     }

   @media only screen and (max-width: 730px) {
    width: calc(322px* 1 + 11.5px* 2);
   }
`;

export const HubIcon = styled.div`
`;

export const HeadlineContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    max-height: 106px;
    color: white;
    margin-left: 3%;
`;

export const Headline = styled.h1`
    margin: 0;
    font-family: var(--bold-font);
    font-size: 3rem;
    letter-spacing: -0.01em;
    line-height: 2.7rem;
    text-align: center;
    text-wrap: nowrap;
    display: inline;
    transform: translate(-3px, -3px);

   
    @media only screen and (max-width: 1150px) {
      font-size: 2.8rem;
      line-height: 2.6rem;
     }

   @media only screen and (max-width: 730px) {
    font-size: 1.6rem;
    line-height: 1.8rem;
   }
`;

export const HubColored = styled.span`
    color: #34e1eb;
    /* 7554f0  / 34e1eb*/
    margin-left: 4px;
    font-size: 3rem;
    line-height: 2.7rem;
    letter-spacing: -0.01em;
    text-align: center;

    @media only screen and (max-width: 1150px) {
      font-size: 2.8rem;
      line-height: 2.6rem;
     }

   @media only screen and (max-width: 730px) {
      font-size: 1.6rem;
      line-height: 1.8rem;
   }
`;

export const Subheader = styled.p`
    font-family: var(--light-font);
    max-height: 20px;
    width: auto;
    margin: 0;
    font-size: 1rem;
    text-wrap: nowrap;

    @media only screen and (max-width: 1150px) {
      font-size: 0.9rem;
      line-height: 1.6rem;
     }

   @media only screen and (max-width: 730px) {
      font-size: 0.7rem;
      line-height: 0.4rem;
      line-break: auto;
   }
`;

export const StyledUser = styled.span`
    font-family: var(--basic-font);

    @media only screen and (max-width: 1150px) {
      font-size: 0.9rem;
      line-height: 1.6rem;
     }

   @media only screen and (max-width: 730px) {
    font-size: 0.7rem;
    line-height: 0.4rem;
   }
`;
