import ServiceBox from "../components/ServiceBox";
import { OverviewContainer } from "../components/styledComponents/Overview.styled";

const Overview = () => {
	return (
		<OverviewContainer>
			<ServiceBox />
		</OverviewContainer>
	);
};

export default Overview;
