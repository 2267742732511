import React, { useEffect, useState } from "react";
import { useAuth } from "../services/auth";
import axios from "axios";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Button,
	IconButton,
	Menu,
	MenuItem,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Select,
	FormControl,
	InputLabel,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const RolesTab = () => {
	const { user } = useAuth();
	const [roles, setRoles] = useState([]);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [currentRole, setCurrentRole] = useState({ name: "" });
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedRole, setSelectedRole] = useState(null);
	const API_URL =
		window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;

	useEffect(() => {
		fetchRoles();
	}, [user]);

	const fetchRoles = async () => {
		try {
			const response = await axios.get(`${API_URL}/api/roles`, {
				headers: { Authorization: `Bearer ${user.access_token}` },
			});
			setRoles(response.data);
		} catch (error) {
			console.error("Error fetching roles:", error);
		}
	};

	const handleAdd = () => {
		setCurrentRole({ name: "" });
		setDialogOpen(true);
	};

	const handleEdit = (role) => {
		setCurrentRole(role);
		setDialogOpen(true);
	};

	const handleDelete = async (id) => {
		try {
			await axios.delete(`${API_URL}/api/roles/${id}`, {
				headers: { Authorization: `Bearer ${user.access_token}` },
			});
			setRoles(roles.filter((role) => role.id !== id));
		} catch (error) {
			console.error("Error deleting role:", error);
		}
	};

	const handleDialogClose = () => {
		setDialogOpen(false);
	};

	const handleDialogSave = async () => {
		try {
			if (currentRole.id) {
				await axios.put(`${API_URL}/api/roles/${currentRole.id}`, currentRole, {
					headers: { Authorization: `Bearer ${user.access_token}` },
				});
				setRoles(
					roles.map((role) =>
						role.id === currentRole.id ? currentRole : role,
					),
				);
			} else {
				const response = await axios.post(`${API_URL}/api/roles`, currentRole, {
					headers: { Authorization: `Bearer ${user.access_token}` },
				});
				setRoles([...roles, response.data]);
			}
			setDialogOpen(false);
		} catch (error) {
			console.error("Error saving role:", error);
		}
	};

	const handleMenuOpen = (event, role) => {
		setAnchorEl(event.currentTarget);
		setSelectedRole(role);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		setSelectedRole(null);
	};

	return (
		<div>
			<h2>Roles</h2>
			<Button
				style={{ color: "white" }}
				variant="contained"
				onClick={handleAdd}
			>
				Add Role
			</Button>
			<TableContainer style={{ color: "white" }}>
				<Table style={{ color: "white" }}>
					<TableHead style={{ color: "white" }}>
						<TableRow style={{ color: "white" }}>
							<TableCell style={{ color: "white" }}>Name</TableCell>
							<TableCell style={{ color: "white" }}>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{roles.map((role) => (
							<TableRow style={{ color: "white" }} key={role.id}>
								<TableCell style={{ color: "white" }}>{role.name}</TableCell>
								<TableCell style={{ color: "white" }}>
									<IconButton
										style={{ color: "white" }}
										onClick={(event) => handleMenuOpen(event, role)}
									>
										<MoreVertIcon />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleMenuClose}
				style={{ color: "white" }}
			>
				<MenuItem
					style={{ color: "white" }}
					onClick={() => {
						handleEdit(selectedRole);
						handleMenuClose();
					}}
				>
					Edit
				</MenuItem>
				<MenuItem
					style={{ color: "white" }}
					onClick={() => {
						handleDelete(selectedRole.id);
						handleMenuClose();
					}}
				>
					Delete
				</MenuItem>
			</Menu>

			<Dialog
				style={{ color: "white" }}
				open={dialogOpen}
				onClose={handleDialogClose}
			>
				<DialogTitle style={{ color: "white" }}>
					{currentRole.id ? "Edit Role" : "Add Role"}
				</DialogTitle>
				<DialogContent style={{ color: "white" }}>
					<TextField
						autoFocus
						margin="dense"
						label="Name"
						type="text"
						fullWidth
						value={currentRole.name}
						onChange={(e) =>
							setCurrentRole({ ...currentRole, name: e.target.value })
						}
						style={{ color: "white" }}
					/>
				</DialogContent>
				<DialogActions style={{ color: "white" }}>
					<Button style={{ color: "white" }} onClick={handleDialogClose}>
						Cancel
					</Button>
					<Button style={{ color: "white" }} onClick={handleDialogSave}>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default RolesTab;
