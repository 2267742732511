import React, { useState } from "react";
import { useAuth } from "../services/auth";

import axios from "axios";
import {
	ProfileWrapper,
	Button,
	TextField,
	Headder,
	Form,
	ButtonWrapper,
	Label,
} from "./styledComponents/Profile.styled";
import CrossIcon from "../assets/CrossIcon";

export default function Profile({ onIconClick }) {
	const { user, logout } = useAuth();
	const [name, setName] = useState(user.profile.name);
	// const [language, setLanguage] = useState(user.profile.locale);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await axios.put(
				`${window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/api/users/profile`,
				{ name },
				{ headers: { Authorization: `Bearer ${user.access_token}` } },
			);
			alert("Profile updated successfully");
		} catch (error) {
			console.error("Error updating profile:", error);
			alert("Failed to update profile");
		}
	};

	return (
		<>
			<ProfileWrapper>
				<CrossIcon onIconClick={onIconClick} />
				{/* onClick={handleProfileClose()}  */}
				<Headder style={{ color: "white" }}>Profil Editieren</Headder>
				<Form
					onSubmit={handleSubmit}
					style={{ display: "flex", flexDirection: "column" }}
				>
					<Label htmlFor="profilName">Profilname</Label>
					<TextField
						label="Name"
						id="profilName"
						value={name}
						onChange={(e) => setName(e.target.value)}
						readOnly
					/>
					{/* <TextField
          fullWidth
          margin="normal"
          label="Preferred Language"
          value={language || ''}
          onChange={(e) => setLanguage(e.target.value)}
        /> */}
					<ButtonWrapper>
						<Button type="submit" disabled>
							Aktualisieren
						</Button>
					</ButtonWrapper>
				</Form>
			</ProfileWrapper>
		</>
	);
}
