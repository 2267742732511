import React, { useEffect, useState } from "react";
import { useAuth } from "../services/auth";
// import { IconButton } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import {
	Banner,
	ShellBar,
	ShellLink,
	Wrapper,
	BlxLogo,
	HubIcon,
	HeadlineContainer,
	Headline,
	Subheader,
	HubColored,
	AdminLink,
	IconAndHeader,
	UserMenu,
	UserInitials,
	MenuEntry,
	UserInfoContainer,
	UserInfo,
	UserName,
	UserEmail,
	StyledDivider,
	StyledUser,
	HeadUser,
	UserButton,
} from "./styledComponents/Header.styled.js";
import logo from "../assets/logo.png";
import HubSvg from "../assets/HubSvg.js";
import Profile from "./Profile.js";
import { BlurrBackdrop } from "./styledComponents/Profile.styled.js";

const Navigation = () => {
	const { user, roles, logout } = useAuth();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [profileActive, setProfileActive] = useState(false);

	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		handleMenuClose();
		logout();
	};

	const getInitials = (name) => {
		const initials = name
			.split(" ")
			.map((part) => part[0])
			.join("");
		return initials.toUpperCase();
	};

	// customerLogo is just a placeholder for a future endpoint. Check later of enpoints match.
	const profilePicture = user?.profile?.picture;
	const userName = user?.profile?.name ? user?.profile?.name : "";

	const organisation = user?.profile["urn:zitadel:iam:user:resourceowner:name"];

	const hasAdminAccess = roles.includes("buildlinx-admin");

	function handleClickProfile() {
		setAnchorEl(null);
		setProfileActive(true);
	}

	const handleProfileClose = () => {
		setProfileActive(false);
	};

	useEffect(() => {
		document.body.style.overflow = profileActive ? "hidden" : "auto";
		// Cleanup function to reset overflow on component unmount
		return () => {
			document.body.style.overflow = "auto";
		};
	}, [profileActive]);

	return (
		<>
			<ShellBar>
				<ShellLink href="/">
					<BlxLogo src={logo} alt="Buildlinx logo" />
				</ShellLink>

				<Wrapper>
					{hasAdminAccess && <AdminLink to="/admin">Admin Interface</AdminLink>}
					<HeadUser>{userName}</HeadUser>
					<UserButton onClick={handleMenuOpen}>
						<UserInitials src={profilePicture} alt={userName}>
							{!profilePicture && getInitials(userName)}
						</UserInitials>
					</UserButton>
				</Wrapper>

				<UserMenu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleMenuClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					<UserInfoContainer>
						<UserInitials src={profilePicture} alt={userName}>
							{!profilePicture && getInitials(userName)}
						</UserInitials>
						<UserInfo>
							<UserName>{userName}</UserName>
							<UserEmail>{user?.profile?.email || "No email"}</UserEmail>
						</UserInfo>
					</UserInfoContainer>
					<StyledDivider />
					<MenuEntry onClick={handleClickProfile}>
						{/* component={Link} to="/profile"  */}
						<PersonIcon />
						Profileinstellungen
					</MenuEntry>
					<StyledDivider />
					<MenuEntry onClick={handleLogout}>
						<LogoutIcon />
						Abmelden
					</MenuEntry>
				</UserMenu>
			</ShellBar>

			<Banner>
				<IconAndHeader>
					<HubIcon>
						<HubSvg />
					</HubIcon>

					<HeadlineContainer>
						<Headline>
							{!organisation ? "Buildlinx" : organisation}
							<HubColored>App Hub</HubColored>
						</Headline>
						{userName !== "" ? (
							<Subheader>
								Willkommen <StyledUser>{userName}</StyledUser>, hier finden Sie
								ihre Apps
							</Subheader>
						) : null}
					</HeadlineContainer>
				</IconAndHeader>
			</Banner>
			{profileActive ? (
				<>
					<Profile onIconClick={handleProfileClose} />
					<BlurrBackdrop onClick={handleProfileClose} />
				</>
			) : null}
		</>
	);
};

export default Navigation;
