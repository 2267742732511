import styled, { keyframes } from "styled-components";

export const TheBox = styled.div`
`;

export const ServiceContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: auto;
    gap: 11.49px;
    padding-top: 30px;
    padding-bottom: 50px;

    @media only screen and (max-width: 1150px) {
        grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: 730px) {
        grid-template-columns: 1fr;
    }
`;

export const ServiceItem = styled.a`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-self: stretch;
    align-self: stretch;
    max-width: 312px;
    color: white;
    background-color: #0E1F31;
    background-image: radial-gradient(49.85% 49.85% at 50.06% 0%, rgba(106, 198, 198, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
    border: 1.03px solid #373B3C;
    border-radius: 9.979px; 
    padding: 15px 15px 6px 15px;
    font-size: clamp(0.5rem, 1vw, 2rem);
    line-height: 20px;
    text-decoration: none;

    @media only screen and (min-width: 1150px ){
        min-height: 103px;
    }

    &:hover{
        cursor: pointer;
        border-color: rgba(77, 237, 242, 0.6);
        box-shadow: 0 2px 20px rgba(117, 84, 240, .25);
    }

    transition: border-color 200ms ease-in-out;
`;

export const Subheader = styled.h2`
    font-family: var(--bold-font);
    color: white;
`;

export const IconAndNameWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    /* margin-bottom: 9px; */
`;

export const ServiceTitle = styled.h4`
    margin: 0;
    font-size: clamp(0.8rem, 1.1rem, 1.3rem);
    line-height: 18px;
    max-width: 71%;
    font-family: var(--basic-font);
    text-wrap: balance;

    @media only screen and (max-width: 400px) {
        line-height: 20px;
    }
`;

export const SvgWrapper = styled.svg`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0;
`;

export const InformationWrapper = styled.div`
    display: block;
    position: relative;
    justify-content: flex-end;
    width: 10%;
    align-self: flex-end;
    transform: translate(308px, -25px);
    cursor: pointer;

    &:hover{
        cursor: pointer;
    }
`;

export const Description = styled.p`
    transition: height 450ms ease-in-out 100ms;
    height: ${(props) => (props.$hover ? "60px" : "0")};
    width: 82%;
    overflow-y: clip;
    font-size: 0.75rem;
    font-family: var(--light-font);
    word-break: break-word;
    hyphens: auto;
    @media (prefers-reduced-motion) {
    transition: height 2s ease-in-out 100ms;}
`;

export const TrademartStatement = styled.p`
    position: relative  ;
    transform: translate(85px, -45px);
    width: fit-content;
    max-width: 200px;
    font-family: var(--light-font);
    font-size: 0.3rem;
    color: darkgrey;
    text-align: left;
    margin: 7px 8% 0 0;
    word-break: break-all;
`;

export const Jump = keyframes`0%, 100% {
    transform: translateY(0px);
    animation-timing-function: cubic-bezier(0.3, 0, 0.1, 1);
}
50% {
    transform: translateY(-50px);
    animation-timing-function: cubic-bezier(0.9, 0, 0.7, 1);
}`;

const bounce = keyframes`
0%  {
    transform: scaleX(1.3) scaleY(0.8);
    top: 250px;
    animation-timing-function: cubic-bezier(0.3, 0, 0.1, 1);
    }
    15% {
    transform: scaleX(0.75) scaleY(1.25);
    animation-timing-function: cubic-bezier(0, 0, 0.7, 0.75);
}
55% {
    transform: scaleX(1.05) scaleY(0.95);
    animation-timing-function: cubic-bezier(0.9, 0, 1, 1);
}
95% {
    transform: scaleX(0.75) scaleY(1.25);
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
}
100%{
    transform: scaleX(1.3) scaleY(0.8);
    top: 250px;
    animation-timing-function: cubic-bezier(0, 0, 0.7, 1);
    }
`;

export const LoadingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin-top: 20%;
    justify-content: flex-start;
    align-items: center;
    background-color: #030D1A;
`;

export const Bouncer = styled.img`
    position: relative;
    /* margin-top: 50px; */
    animation: ${bounce} 0.8s ease-in-out infinite;
    animation: ${Jump} 0.8s ease-in-out infinite;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    `;
