import {
	LinkWrapper,
	Rights,
	LastLine,
	Credentials,
	FooterBar,
	LicenseLink,
} from "./styledComponents/Footer.styled";

export default function Footer() {
	return (
		<FooterBar>
			<LastLine>
				<Rights>Alle Rechte vorbehalten.</Rights>

				<LinkWrapper>
					<Credentials href="https://www.buildlinx.de/impressum">
						Impressum
					</Credentials>
					<Credentials
						href="https://www.buildlinx.de/datenschutz"
						style={{
							marginLeft: "15px",
						}}
					>
						Datenschutz
					</Credentials>
					<LicenseLink
						to="/license"
						style={{
							marginLeft: "15px",
						}}
					>
						Licenses & Copyrights
					</LicenseLink>
				</LinkWrapper>
			</LastLine>
		</FooterBar>
	);
}
