// src/components/AdminInterface.js
import React from "react";
import { Container } from "@mui/material";
import AdminPanel from "../components/AdminPanel";

const AdminInterface = () => {
	return (
		<Container>
			<h1 style={{ color: "white" }}>Admin Interface</h1>
			<AdminPanel style={{ color: "white" }} />{" "}
			{/* Render the AdminPanel here */}
		</Container>
	);
};

export default AdminInterface;
