// src/pages/Callback.js
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../services/auth";
import img from "../assets/buildlinx-tab-logo.png";
import { LoadingWrapper } from "../components/styledComponents/ServiceBox.styled";
import { Bouncer } from "../components/styledComponents/ServiceBox.styled";

function Callback() {
	const { handleCallback } = useAuth();
	const navigate = useNavigate();
	const hasHandledCallback = useRef(false);

	useEffect(() => {
		const processCallback = async () => {
			if (hasHandledCallback.current) return; // Prevent multiple executions
			hasHandledCallback.current = true;

			try {
				await handleCallback();
				navigate("/overview");
			} catch (error) {
				console.error("Callback processing error:", error);
				navigate("/login");
			}
		};

		processCallback();
	}, [handleCallback, navigate]);

	return (
		<LoadingWrapper>
			<Bouncer height="50" width={"50"} src={img} />
		</LoadingWrapper>
	);
}

export default Callback;
