// src/components/AdminPanel.js
import React from "react";
import { AppBar, Tabs, Tab, Box } from "@mui/material";
import ServicesTab from "./ServicesTab";
import TenantsTab from "./TenantsTab";
import RolesTab from "./RolesTab";
import TenantServicesTab from "./TenantServicesTab";

const AdminPanel = () => {
	const [tabIndex, setTabIndex] = React.useState(0);

	const handleTabChange = (event, newValue) => {
		setTabIndex(newValue);
	};

	return (
		<Box>
			<AppBar position="static" style={{ color: "white" }}>
				<Tabs
					value={tabIndex}
					onChange={handleTabChange}
					style={{ color: "white" }}
				>
					<Tab label="Services" style={{ color: "white" }} />
					<Tab label="Tenants" style={{ color: "white" }} />
					<Tab label="Roles" style={{ color: "white" }} />
					<Tab label="Tenant Services" style={{ color: "white" }} />
				</Tabs>
			</AppBar>

			<Box p={2} style={{ color: "white" }}>
				{tabIndex === 0 && <ServicesTab style={{ color: "white" }} />}
				{tabIndex === 1 && <TenantsTab style={{ color: "white" }} />}
				{tabIndex === 2 && <RolesTab style={{ color: "white" }} />}
				{tabIndex === 3 && <TenantServicesTab style={{ color: "white" }} />}
			</Box>
		</Box>
	);
};

export default AdminPanel;
